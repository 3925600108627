import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../config'

const Alerts = () => {
    const [loading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(
        typeof window !== 'undefined' && window.location.search
    )
    let unsubscribeEmail = queryParams.get('email')
    let alertType = queryParams.get('type')

    useEffect(() => {
        if (unsubscribeEmail) {
            let url = '';
            if (alertType === 'alert') {
                url = strapiURL + `/delete-job-alerts-email/`;
            }
            else if (alertType === 'search') {
                url = strapiURL + `/delete-candidate-search-alert/`;
            }
            else {
                url = strapiURL + `/unsubscribe-job-alert/`;
            }

            axios
                .put(url, { email: decodeURI(unsubscribeEmail) })
                .then(res => {
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }, [])

    return (
        <div className="container mx-auto px-6 sm:px-0">
            {loading ? (
                <div
                    className="w-full bg-no-repeat bg-cover py-44"
                    style={{
                        backgroundImage: `url(/loaders/table-loader.png)`,
                    }}
                />
            ) : (
                <>
                    <div>
                        <p style={{ textAlign: 'center', fontSize: '20px', marginTop: '100px', marginBottom: '100px', color: 'green', fontWeight: 'bold' }}>
                            You've successfully unsubscribed from this job alert.
                        </p>
                    </div>
                </>
            )}
        </div>
    )
}
export default Alerts